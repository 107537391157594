import React from "react";
import Skill from "./Skill";
import "./About.css";
import ProfileImage from "./media/deborah.jpg";
import Logo from "./media/logo.png";
import WorkSpace from "./media/workspace.jpg";
import { Popover, OverlayTrigger } from "react-bootstrap";

export default function About() {
  const skills = [
    "React",
    "HTML",
    "CSS",
    "JavaScript",
    "Bootstrap",
    "Github",
    "Java",
    "C#",
    "C++",
    "Photoshop",
    "Lightroom",
    "Illustrator",
  ];
  const popoverStyle = {
    border: "none",
    borderRadius: "1rem",
    padding: "1rem",
    textAlign: "center",
  };

  const groupButterflyStyle = {
    position: "absolute",
    left: "39.2%",
    top: "15.18%",
    width: "18%",
    height: "55.59%",
    zIndex: "2",
  };

  const doodleStyle = {
    position: "absolute",
    left: "0%",
    top: "0%",
    width: "33.8%",
    height: "64.54%",
    zIndex: "2",
  };

  const codeCodeStyle = {
    position: "absolute",
    left: "54.8%",
    top: "0%",
    width: "45.1%",
    height: "53.67%",
    zIndex: "2",
  };

  const codeCodeStyleTwo = {
    position: "absolute",
    left: "25.5%",
    top: "0%",
    width: "27.3%",
    height: "16.45%",
    zIndex: "2",
  };

  const draftNotebookStyle = {
    position: "absolute",
    left: "53.3%",
    top: "50.64%",
    width: "44.2%",
    height: "49.52%",
    zIndex: "2",
  };

  const codeCode = (
    <Popover
      className="workspace-description"
      id="popover-click"
      style={popoverStyle}
    >
      <h6>Laptop 💻</h6>
      <p>Where most of the action happens.</p>
    </Popover>
  );

  const sketchBook = (
    <Popover
      className="workspace-description"
      id="popover-click"
      style={popoverStyle}
    >
      <h6>Sketchbook</h6>
      <p>
        Occasionally, I doodle for fun or to clear my mind. Other times, it's to
        generate new ideas.
      </p>
    </Popover>
  );

  const groupButterfly = (
    <Popover
      className="workspace-description"
      id="popover-click"
      style={popoverStyle}
    >
      <h6>Origami Butterflies</h6>
      <p>Solely for aesthetic purposes</p>
    </Popover>
  );

  const notebook = (
    <Popover
      className="workspace-description"
      id="popover-click"
      style={popoverStyle}
      title="Popover right"
    >
      <h6>Notebook</h6>
      <p>It all starts here. With scribbles.</p>
      <p>Home of the pseudo-code, the wireframe and the logo designs</p>
    </Popover>
  );

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    reveals.forEach((el) => {
      var windowHeight = window.innerHeight;
      var elementTop = el.getBoundingClientRect().top;
      var elementVisible = 200;
      if (elementTop < windowHeight - elementVisible && elementTop > 80) {
        el.classList.add("active-now");
      } else if (elementTop <= 80) {
        el.classList.add("seen");
      } else {
        el.classList.remove("active-now");
        el.classList.remove("seen");
      }
    });
  }

  window.addEventListener("scroll", reveal);

  reveal();

  return (
    <section className="About" id="about">
      <div className="text-content-lavendar">
        <h4>About</h4>
        <div className="row intro justify-content-center">
          <div className="col-md-3 mb-5">
            <div className="profilepic-wrapper">
              <img
                src={ProfileImage}
                className="profile-img"
                alt="Deborah Adadewa Calgary Front-End Developer"
              />

              <img
                src={Logo}
                className="profile-img-logo reveal"
                alt="Deborah"
                style={{ zIndex: 2 }}
              />
            </div>
          </div>
          <div className="col-md-8 justify-content-left description">
            <p>
              Hi, I am Deborah—a Web/Software Developer based in Alberta,
              Canada. I am passionate about making user-friendly websites,
              photography and logo design.
            </p>
            <p>
              {" "}
              This gives me a well-rounded perspective on every project I work
              on.
            </p>
            <p>
              Want to get in touch? Head over to the contact page to connect!
            </p>
          </div>
        </div>

        <div className="row language-education">
          <div className="col skill">
            <h5>Languages/Skills:</h5>
            <Skill skill={skills} />
          </div>

          <div className="col education">
            <h5>Education:</h5>
            <p>Bachelor of Computer Information Systems - (MRU)</p>
            <p>Front-End Development Certificate - (SheCodes)</p>
            <p>Software Development - (BVC)</p>
          </div>
        </div>
        <div className="col" id="workspace">
          <h4 className="workspace-title">My Workspace</h4>
          <div className="workspace-outline">
            <p className="workspace-note">
              Click on any item in the picture below to find out more
            </p>
          </div>
        </div>
        <div className="container row d-block d-lg-flex workspace-image-container">
          <div className="workspace col-12" style={{ position: "relative" }}>
            <div className="workspace-img">
              <img src={WorkSpace} alt="My workspace" className="desk-img" />
            </div>

            <OverlayTrigger
              trigger={"click"}
              rootClose
              placement="bottom"
              overlay={groupButterfly}
              className="workspace-description"
            >
              <button
                title="Origami Butterflies"
                style={groupButterflyStyle}
                href="#app"
                className="workspace-btn"
              />
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              rootClose
              placement="right"
              overlay={sketchBook}
              className="workspace-description"
            >
              <button
                title="Sketch Book"
                style={doodleStyle}
                href="#app"
                className="workspace-btn"
              />
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={codeCode}
              className="workspace-description"
            >
              <button
                title="Code code code"
                href="#app"
                style={codeCodeStyle}
                className="workspace-btn"
              />
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={codeCode}
              className="workspace-description"
            >
              <button
                title="Code code code"
                href="#app"
                style={codeCodeStyleTwo}
                className="workspace-btn"
              />
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose
              overlay={notebook}
              className="workspace-description"
            >
              <button
                title="Notebook"
                href="#app"
                style={draftNotebookStyle}
                className="workspace-btn"
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </section>
  );
}
