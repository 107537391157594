import logo from "./media/logo.png";
import "./App.css";
import Navbar from "./Navbar";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";

export default function App() {
  return (
    <div
      className="App fade-in"
      id="app"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse.show"
    >
      <div className="container text-dark center" id="container">
        <Navbar />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Deborah Adadewa</h1>
          <p className="header-p">Web Developer &amp; Graphic Designer</p>
        </header>
        <About />
        <Work />
        <Contact />
        <footer>
          <p>
            &copy;Made by{" "}
            <a href="https://github.com/deborahadadewa/" className="App-link">
              {" "}
              Deborah Adadewa
            </a>{" "}
            |{" "}
            <a
              href="https://www.flaticon.com/authors/amazona-adorada"
              className="App-link"
              target="_blank"
            >
              flaticon.com
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}
